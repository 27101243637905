import React, { useState, useRef, useEffect } from 'react';
import { Field, autofill, getFormValues, change } from 'redux-form';
import { trackEvent } from 'user-analytics';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { updatePurchaserData } from '@/actions/siemprePlus';
import { fieldRenderer } from 'utils/formRenderers';
import { Spacing, Text } from '@reservamos/elements';
import DocumentTypeField from 'components/DocumentTypeField';
import normalizeSpaces from 'utils/normalizeSpaces';
import { documentId, required } from 'utils/formValidations';
import { getDocumentTypeInstructioni18NKey } from 'utils/documentMaps';
import normalizeName from '../../../utils/normalizeName';
import { PhoneNumberField } from '../../ReduxFields';
import LabelSwitch from '../../../ui/atoms/LabelSwitch';
import {
  FIRST_DATA_PASSENGER_SWITCH_CLICKED,
  SWITCH_VALUE_PROP,
} from '../../../constants/TrackEvents';
import usePurchase from '../../../hooks/store/usePurchase';
import useLoyaltyPrograms from '../../../loyalty/context/useLoyaltyPrograms';
import { getUserByWalletType, walletTypeNeedsUserInPurchaser } from '../../../utils/loyalty';
import parsePurchaser from '../../../utils/purchase/parsePurchaser';

/**
 * PurchaserFields component renders the form fields for the purchaser's information.
 * It handles autofill functionality, loyalty program integration, and form validation.
 *
 * @param {Object} props - The component props
 * @param {Object} props.purchaser - The purchaser's data
 * @param {boolean} props.isExchange - Flag indicating if the purchase is an exchange
 * @param {boolean} props.showPurchaserForm - Flag indicating if the purchaser form should be displayed
 *
 * @returns {JSX.Element} The rendered component
 */
const PurchaserFields = ({ purchaser, isExchange, showPurchaserForm }) => {
  const [autofillEnabled, setAutoFillEnabled] = useState(false);
  const { selectedLoyaltyProgram } = useLoyaltyPrograms();
  const purchase = usePurchase();
  const whitelabelConfig = useSelector((state) => state.whitelabelConfig);
  const state = useSelector((state) => state);
  const { t } = useTranslation('passengers');
  const {
    features,
    copies: { others },
    env: { theme },
  } = whitelabelConfig;
  const phoneNumberLabel = theme.phoneNumberLabel || t('phone');
  const dispatch = useDispatch();
  const purchaserFormValues = getFormValues('purchaser')(state);
  const passengersFormValues = getFormValues('passengers')(state);
  const purchaserDocumentType =
    passengersFormValues?.documentType || purchaserFormValues?.documentType;
  const phoneCountry = purchaser?.phoneCountry || purchaserFormValues?.phoneCountry || '';
  const acceptBusinessName = features.ACCEPT_PURCHASER_BUSINESS_NAME;
  const showDocumentField = features.IDENTIFICATION_DOCUMENT_FOR_PURCHASER;

  const initialFields = useRef(null);

  const { walletType } = purchase;

  useEffect(() => {
    if (!showPurchaserForm) return;
    if (!Object.keys(purchaser).length) return;
    if (!initialFields.current) initialFields.current = { ...purchaser };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaser]);

  useEffect(() => {
    if (!isExchange && walletTypeNeedsUserInPurchaser(walletType)) {
      const user = getUserByWalletType(walletType);
      if (user) updatePurchaserData(parsePurchaser(user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletType, selectedLoyaltyProgram]);

  /**
   * Checks if the current field should be disabled
   * @param {String} fieldName - field name to check if it should be disabled
   * @returns Boolean
   */
  const isDisabled = (fieldName) => {
    /**
     * This condition is to disabled the fields when the purchase is an exchange,
     * Checks if the exchange should disable the purchaser fields,
     * Checks if the purchaser form is shown,
     * Checks if the initial values are set (only for exchanges),
     * Checks if the initial values are set for the current field, this is done to avoid the user change it,
     * If a field is not filled, the field is not disabled to be able to fill it. This can happen with terminal purchases.
     */

    const exchangeDisablingCondition =
      isExchange &&
      features.DISABLE_EXCHANGE_PURCHASER &&
      initialFields.current &&
      initialFields.current[fieldName];

    /**
     * Fields are disabled if autofill is enabled, the phone field is not disabled because it is filled in this step.
     * Remember that the autofill function fills the fields with the first passenger data
     */
    const disableBecauseAutoFill = fieldName !== 'phone' && autofillEnabled;

    /**
     * Checks if the loyalty program needs to disable the purchaser data.s
     */
    const loyaltyDisabling =
      walletTypeNeedsUserInPurchaser(selectedLoyaltyProgram) &&
      purchaserFormValues &&
      purchaserFormValues[fieldName];

    return exchangeDisablingCondition || disableBecauseAutoFill || loyaltyDisabling;
  };

  /**
   * Toggles the autofill feature for the purchaser's fields with the first passenger data
   */
  const toggleAutofill = (e) => {
    const { passengers } = purchase;
    const { firstName, lastName, secondLastName, documentType, documentId, email } = passengers[0];

    // Sending analytics event when the switch is clicked
    trackEvent(FIRST_DATA_PASSENGER_SWITCH_CLICKED, {
      [SWITCH_VALUE_PROP]: e.target.checked,
    });

    if (!autofillEnabled) {
      if (firstName) dispatch(autofill('purchaser', 'purchaserFirstName', firstName));
      if (lastName) dispatch(autofill('purchaser', 'purchaserLastName', lastName));
      if (secondLastName)
        dispatch(autofill('purchaser', 'purchaserSecondLastName', secondLastName));
      if (documentType) dispatch(autofill('purchaser', 'documentType', documentType));
      if (documentId) dispatch(autofill('purchaser', 'documentId', documentId));
      if (email) dispatch(autofill('purchaser', 'email', email));
    }
    setAutoFillEnabled(!autofillEnabled);
  };

  /**
   * Handle the change event for the document type field.
   * If the new value is 'NIT', it dispatches an action to change the person type to '1'.
   * on the transfer form.
   *
   * @param {object} event - The event object.
   * @param {string} newValue - The new value of the document type field.
   */
  const handleDocumentTypeChange = (event, newValue) => {
    if (newValue === 'NIT') {
      dispatch(change('transfer', 'personType', '1'));
    }
  };

  /**
   * Renders the document type and document ID fields for the purchaser.
   * The document type field is populated with options based on the purchaser's nationality
   * and the available document types. The document ID field is a text input field.
   * Both fields are conditionally disabled based on the purchaser's form state.
   *
   * @returns {JSX.Element} The rendered document type and document ID fields.
   */
  const renderDocumentField = () => {
    return (
      <Spacing size="S" flexGrow>
        <DocumentTypeField
          name="documentType"
          validate={required}
          target="purchaser"
          isDisabled={isDisabled('documentType')}
          onChange={handleDocumentTypeChange}
        />
        <Spacing size="S" vertical>
          <Field
            name="documentId"
            id="documentId"
            type="text"
            placeholder={t('document_number')}
            normalize={normalizeSpaces}
            component={fieldRenderer}
            isDisabled={isDisabled('documentId')}
            validate={documentId}
          />
          {getDocumentTypeInstructioni18NKey(purchaserDocumentType) ? (
            <Text size="XS" color="accent">
              {t(getDocumentTypeInstructioni18NKey(purchaserDocumentType))}
            </Text>
          ) : null}
        </Spacing>
      </Spacing>
    );
  };

  const showSwitch = features.SHOW_PASSENGER_IS_BUYER && showPurchaserForm && !isExchange;
  const showBusinessNameLabel = acceptBusinessName && purchaserDocumentType === 'NIT';
  return (
    <>
      <div className="passengers-form">
        <Spacing size="S" flexGrow vertical>
          {showSwitch && !walletTypeNeedsUserInPurchaser(selectedLoyaltyProgram) && (
            <LabelSwitch
              checked={autofillEnabled}
              onChange={toggleAutofill}
              id="check-passengers"
              label={t('is_the_buyer_a_passenger_checkout')}
            />
          )}
          {showDocumentField && acceptBusinessName && renderDocumentField()}
          <Spacing size="S" flexGrow>
            <Field
              autoComplete="given-name"
              type="text"
              name="purchaserFirstName"
              id="purchaserFirstName"
              placeholder={showBusinessNameLabel ? t('business_name') : t('fore_name')}
              component={fieldRenderer}
              normalize={normalizeName}
              isDisabled={isDisabled('purchaserFirstName')}
            />
            {!showBusinessNameLabel && (
              <Field
                autoComplete="family-name"
                type="text"
                name="purchaserLastName"
                id="purchaserLastName"
                placeholder={t('family_name_one')}
                component={fieldRenderer}
                normalize={normalizeName}
                isDisabled={isDisabled('purchaserLastName')}
              />
            )}
            {features.SHOW_PURCHASER_SECOND_LAST_NAME_FIELD && !showBusinessNameLabel && (
              <Field
                autoComplete="do-not-autofill"
                type="text"
                name="purchaserSecondLastName"
                id="purchaserSecondLastName"
                placeholder={`${t('family_name_two')} ${t('optional')}`}
                component={fieldRenderer}
                isDisabled={isDisabled('purchaserSecondLastName')}
              />
            )}
          </Spacing>

          <Spacing size="S" isResponsive flexGrow>
            <PhoneNumberField
              autoComplete="tel-national"
              isDisabled={isDisabled('phone')}
              phoneCountryFieldName="phoneCountry"
              phoneCodeFieldName="phoneCode"
              phoneFieldName="phone"
              formName="purchaser"
              placeholder={phoneNumberLabel}
              cls="form-item-wrap-l"
              initCountry={phoneCountry}
            />
            {features.SHOW_PURCHASER_EMAIL_FIELD && (
              <Field
                id="email"
                type="email"
                name="email"
                placeholder={t('email')}
                component={fieldRenderer}
                isDisabled={isDisabled('email')}
              />
            )}
          </Spacing>

          {showDocumentField && !acceptBusinessName && renderDocumentField()}
          {Boolean(others && others.purchaserNotice) && (
            <Text size="S">{others.purchaserNotice}</Text>
          )}
        </Spacing>
      </div>
    </>
  );
};

PurchaserFields.propTypes = {
  purchaser: PropTypes.object,
  isExchange: PropTypes.bool,
  initialValues: PropTypes.shape({
    phoneCountry: PropTypes.string,
  }),
  showPurchaserForm: PropTypes.bool,
};

export default PurchaserFields;
