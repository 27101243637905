import React from 'react';
import PropTypes from 'prop-types';
import './TicketSummary.scss';
import { useTranslation } from 'react-i18next';
import { Box, Link, Spacing, Text } from '@reservamos/elements';
import DownloadButton from '../atoms/DownloadButton';

/**
 * TicketSummary Component
 * Component that renders a summarized ticket information block. It displays details such as the title, date, departure, and arrival information, along with a downloadable button.
 * @component
 * @param {string} title - The title of the ticket.
 * @param {string} date - The date of the ticket.
 * @param {string} originTime - The departure time.
 * @param {string} originTerminal - The departure terminal.
 * @param {string} originCity - The city of departure.
 * @param {string} originLocation - The location link for departure (optional).
 * @param {string} destinationTime - The arrival time.
 * @param {string} destinationTerminal - The arrival terminal.
 * @param {string} destinationCity - The city of arrival.
 * @param {string} destinationLocation - The location link for arrival (optional).
 * @param {string} href - The URL for downloading the ticket.
 * @param {string} buttonLabel - The label for the download button (optional).
 * @returns {JSX.Element} - The rendered TicketSummary component.
 */
const TicketSummary = ({
  title,
  date,
  href,
  buttonLabel,
  originTime,
  originTerminal,
  originCity,
  originLocation,
  destinationTime,
  destinationTerminal,
  destinationCity,
  destinationLocation,
}) => {
  const { t } = useTranslation('purchase');

  return (
    <Spacing size="S" vertical>
      {title ? (
        <Text size="L" weight="bold">
          {`${title} - ${date}`}
        </Text>
      ) : null}
      <Box
        alphaBg="XS"
        bgColor="grayLight"
        borderRadius="S"
        hasShadow
        paddingHorizontal="S"
        paddingVertical="S"
      >
        <Spacing size="XS" vertical>
          <div className="ticket-summary">
            <Spacing size="XS" vertical>
              <Spacing size="S">
                <Text size="S">
                  {t('label.departure')}: <b>{originTime}</b>
                </Text>
              </Spacing>
              <Text weight="semibold" size="L" mobileSize="M">
                {`${originTerminal} `}
                {Boolean(originLocation) && (
                  <Link
                    type="primary"
                    text={t('label.view_location')}
                    href={originLocation}
                    newTab
                  />
                )}
              </Text>
              <Text size="S">{originCity}</Text>
            </Spacing>
            <Spacing size="XS" vertical>
              <Spacing size="S">
                <Text size="S">
                  {t('label.arrival')}: <b>{destinationTime}</b>
                </Text>
              </Spacing>
              <Text weight="semibold" size="L" mobileSize="M">
                {`${destinationTerminal} `}
                {Boolean(destinationLocation) && (
                  <Link
                    type="primary"
                    text={t('label.view_location')}
                    href={destinationLocation}
                    newTab
                  />
                )}
              </Text>
              <Text size="S">{destinationCity}</Text>
            </Spacing>
          </div>
          <div>
            <DownloadButton label={buttonLabel} href={href} />
          </div>
        </Spacing>
      </Box>
    </Spacing>
  );
};

TicketSummary.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  originTime: PropTypes.string.isRequired,
  originTerminal: PropTypes.string.isRequired,
  originCity: PropTypes.string.isRequired,
  originLocation: PropTypes.string.isRequired,
  destinationTime: PropTypes.string.isRequired,
  destinationTerminal: PropTypes.string.isRequired,
  destinationCity: PropTypes.string.isRequired,
  destinationLocation: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
};

TicketSummary.defaultProps = {
  buttonLabel: '',
};

export default TicketSummary;
